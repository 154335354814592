<template>
  <div class="onboarding__container gradient" style="height: 90vh">
    <div style="display: flex; flex-direction: column">
      <div style="display: flex; flex-direction: column">
        <h4 style="text-align: center; padding-right:35px;" class="smalltitle">That was quick :)</h4>

        <div style="align-self: center">
          <img src="@/../public/img/brand/final_art.svg" alt="" />
        </div>
          <hr />
      </div>

      <div class="gift__container">
        <h4 class="smalltitle center_text">Get more out of Carl with premium!</h4>
        <br>
        <div class="feature_container" style="padding-left:30px">
        <ul style="list-style-type:none;display: inline-block; text-align: left;">
          <li> <p class="upsell_typography">
            <img
              src="@/../public/img/brand/upsell_shape.svg"
              alt=""
            />Voice-Role links
          </p> </li>
         <li> <p class="upsell_typography">
            <img src="@/../public/img/brand/upsell_shape.svg" alt="" />Auto-Star
            for Starboard
          </p> </li>
         <li> <p class="upsell_typography">
            <img src="@/../public/img/brand/upsell_shape.svg" alt="" />Temporary
            Reaction Roles
          </p> </li>
         <li> <p class="upsell_typography">
            <img src="@/../public/img/brand/upsell_shape.svg" alt="" />Higher
            limits
          </p> </li>
          </ul>
        </div>
        <a href="https://www.patreon.com/join/carlbot">
            <img
              alt="become a patron"
              src="@/../public/img/brand/BecomeAPatron.png"
            />
          </a>
        <hr />
        <br>
        <h4 class="tinytitle center_text">Carl-bot is part of Bot Labs!</h4><br><br>
        <div style="align-items: center; display: flex; flex-direction: column">
          <button class="skip-btn" @click="skipOnboarding">
            GO TO DASHBOARD
            <img src="@/../public/img/brand/right_chevron.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
    <img
      v-for="coord in turtle_coordinates"
      :key="coord"
      :style="{
        position: 'absolute',
        left: `${coord[0]}%`,
        right: `${coord[1]}%`,
        top: `${coord[2]}%`,
        bottom: `${coord[3]}%`,
        opacity: 0.3,
      }"
      src="@/../public/img/brand/newcarl.svg"
      alt=""
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      turtle_coordinates: [
        [1.29, 14.38, 62.43, 40.51],
        [13.29, 14.38, 77.43, 40.51],
        [3.29, 14.38, 24.43, 40.51],
        [1.29, 14.38, 18.43, 40.51],
        [17.29, 14.38, 54.43, 40.51],
        [27.29, 24.38, 14.43, 40.51],
        [57.29, 24.38, 14.43, 40.51],
        [67.29, 24.38, 4.43, 40.51],
        [77.29, 24.38, 44.43, 40.51],
        [87.29, 24.38, 84.43, 40.51],
        [67.29, 24.38, 94.43, 40.51],
      ],
    };
  },
  methods: {
    skipOnboarding() {
      const guild_id = this.$route.params.guild_id;
      this.$router.push(`/dashboard/${guild_id}`);
    },
    startOnboarding() {
      const guild_id = this.$route.params.guild_id;
      this.$router.push(`/onboarding/${guild_id}/general`);
    },
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.gift__container {
    text-align: center;
  background-color: #333741;
  padding: 1rem 5rem;
}

.feature_container {
  display: flex;
}

.upsell_typography {
  /* 20px / Regular */
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  color: #dcf7f9;
  margin-left: 1rem;
}

.center_text {
  text-align: center;
}
.gradient {
  background: rgb(41, 42, 51);
  background: linear-gradient(
    90deg,
    rgba(41, 42, 51, 1) 0%,
    rgba(58, 60, 71, 1) 100%
  );
}

.bread__text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 24px;
  text-align: center;
}

.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}
</style>
<style scoped>
.skip-btn {
  border: none;
  background-color: unset;
  color: white;
  font-family: poppins;
}
</style>
