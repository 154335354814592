var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onboarding__container gradient",staticStyle:{"height":"90vh"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_vm._m(0),_c('div',{staticClass:"gift__container"},[_c('h4',{staticClass:"smalltitle center_text"},[_vm._v("Get more out of Carl with premium!")]),_c('br'),_vm._m(1),_vm._m(2),_c('hr'),_c('br'),_c('h4',{staticClass:"tinytitle center_text"},[_vm._v("Carl-bot is part of Bot Labs!")]),_c('br'),_c('br'),_c('div',{staticStyle:{"align-items":"center","display":"flex","flex-direction":"column"}},[_c('button',{staticClass:"skip-btn",on:{"click":_vm.skipOnboarding}},[_vm._v(" GO TO DASHBOARD "),_c('img',{attrs:{"src":require("@/../public/img/brand/right_chevron.svg"),"alt":""}})])])])]),_vm._l((_vm.turtle_coordinates),function(coord){return _c('img',{key:coord,style:({
      position: 'absolute',
      left: ((coord[0]) + "%"),
      right: ((coord[1]) + "%"),
      top: ((coord[2]) + "%"),
      bottom: ((coord[3]) + "%"),
      opacity: 0.3,
    }),attrs:{"src":require("@/../public/img/brand/newcarl.svg"),"alt":""}})})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('h4',{staticClass:"smalltitle",staticStyle:{"text-align":"center","padding-right":"35px"}},[_vm._v("That was quick :)")]),_c('div',{staticStyle:{"align-self":"center"}},[_c('img',{attrs:{"src":require("@/../public/img/brand/final_art.svg"),"alt":""}})]),_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feature_container",staticStyle:{"padding-left":"30px"}},[_c('ul',{staticStyle:{"list-style-type":"none","display":"inline-block","text-align":"left"}},[_c('li',[_c('p',{staticClass:"upsell_typography"},[_c('img',{attrs:{"src":require("@/../public/img/brand/upsell_shape.svg"),"alt":""}}),_vm._v("Voice-Role links ")])]),_c('li',[_c('p',{staticClass:"upsell_typography"},[_c('img',{attrs:{"src":require("@/../public/img/brand/upsell_shape.svg"),"alt":""}}),_vm._v("Auto-Star for Starboard ")])]),_c('li',[_c('p',{staticClass:"upsell_typography"},[_c('img',{attrs:{"src":require("@/../public/img/brand/upsell_shape.svg"),"alt":""}}),_vm._v("Temporary Reaction Roles ")])]),_c('li',[_c('p',{staticClass:"upsell_typography"},[_c('img',{attrs:{"src":require("@/../public/img/brand/upsell_shape.svg"),"alt":""}}),_vm._v("Higher limits ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.patreon.com/join/carlbot"}},[_c('img',{attrs:{"alt":"become a patron","src":require("@/../public/img/brand/BecomeAPatron.png")}})])}]

export { render, staticRenderFns }